import { render, staticRenderFns } from "./actualData.vue?vue&type=template&id=6a74ec09&scoped=true"
import script from "./actualData.vue?vue&type=script&lang=js"
export * from "./actualData.vue?vue&type=script&lang=js"
import style0 from "./actualData.vue?vue&type=style&index=0&id=6a74ec09&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a74ec09",
  null
  
)

export default component.exports