import request from '@/utils/vmRequest'

// 获取公钥
export function getPublicKey() {
    return request({
        url: '/evo-apigw/evo-oauth/1.0.0/oauth/public-key',
        method: 'get'
    });
}

// 获取认证信息（token）
export function getIccToken(data) {
    return request({
        url: '/evo-apigw/evo-oauth/1.0.0/oauth/extend/token',
        method: 'post',
        data
    });
}

// 获取通道列表
export function getDeviceList(data) {
    return request({
        url: '/evo-apigw/evo-brm/1.2.0/device/channel/subsystem/page',
        method: 'post',
        data
    });
}

// 获取通道详情信息
export function getDeviceDetails(deviceCode) {
    return request({
        url: `/evo-apigw/evo-brm/1.0.0/device/${deviceCode}`,
        method: 'get'
    });
}

// 过车记录查询
export function queryOfPassingRecords(data) {
    return request({
        url: '/evo-apigw/evo-car/1.0.0/carCommonQuery/getPicRecords',
        method: 'post',
        data
    });
}

// 获取过车记录总数
export function getPassingTotal(data) {
    return request({
        url: '/evo-apigw/evo-car/1.0.0/carCommonQuery/getPicRecordTotal',
        method: 'post',
        data
    });
}