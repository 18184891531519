import axios from 'axios'
// import { Modal } from 'ant-design-vue'
// import store from '@/store'
import { getToken, removeToken } from '@/utils/auth'

const service = axios.create({
  baseURL: '/api/icc',
  timeout: 30 * 100000
})


service.interceptors.request.use(
  config => {
    if (getToken('vm-Token')) {
      config.headers['Authorization'] = `bearer ${getToken('vm-Token')}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    return res
  },
  error => {
    let data = error.response.data
    return Promise.reject(error)
  }
)

export default service
