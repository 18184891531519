<!-- 道路测速 -- 实际数据 -->
<template>
  <div id="velocityMeasurement-actualData" v-loading="loading" :element-loading-text="$t('doortimetable.a1')">
    <my-PageTitle>{{ $t('velocityMeasurement.a37') }}</my-PageTitle>
    <div class="main">
      <div class="inquire">
        <a-row :gutter="16" type="flex" align="middle">
          <a-col :span="9">
            <a-input-group compact>
              <!-- 初始日期 -->
              <div style=" width: 45%">
                <a-date-picker v-model="startDate" :disabled-date="disabledStartDate" show-time :allowClear="false" format="YYYY-MM-DD HH:mm:ss" @change="handleCalendarChange" style="width: 100%"/>
              </div>
              <!-- 过渡线 -->
              <div style=" width: 10%">
                <a-input style=" pointer-events: none; backgroundColor: #fff;text-align: center;padding:0px" placeholder="~"/>
              </div>
              <!-- 终止日期 -->
              <div style=" width: 45%">
                <a-date-picker v-model="endDate" :disabled-date="disabledEndDate" show-time :allowClear="false" format="YYYY-MM-DD HH:mm:ss" style="width: 100%"/>
              </div>
            </a-input-group>
          </a-col>
          <!-- 查询按钮 -->
          <a-col :span="3">
            <a-button type="primary" @click="getChartData">
              {{ $t('velocityMeasurement.a11') }}
            </a-button>
          </a-col>
        </a-row>
      </div>
      <!-- 图标 -->
      <div class="main-echarts">
        <div id="velocityMeasurement_actualData_echarts" :style="size"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDeviceList, queryOfPassingRecords, getPassingTotal } from '../../api/velocityMeasurement'
import PageTitle from '../../components/Title/PageTitle.vue'
import { EleResize } from '../../utils/esresize'
import moment from 'moment'

export default {
  components:{
      'my-PageTitle': PageTitle
    },
  data(){
    return{
      loading: false,
      size: { width: "100%", height: "100%" },
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),

      option: {
        title: {
          subtext: "",
          right: "10px",
          subtextStyle: {
            fontSize: 16,
          },
        },
        color: ["#91cc75", "#ee6666"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "45px",
          left: "30px",
          right: "10px",
          bottom: "20px",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: [],
          },
        ],
        yAxis: [
          {
            // name: this.$t("consume.a115"),
            type: "value",
          },
        ],
        legend: {
          data: [
            this.$t('velocityMeasurement.a42'),
            this.$t('velocityMeasurement.a43')
          ],
        },
        series: [
          {
            name: this.$t('velocityMeasurement.a42'),
            type: "bar",
            barGap: 0,
            emphasis: {
              focus: "series",
            },
            data: []
          },
          {
            name: this.$t('velocityMeasurement.a43'),
            type: "bar",
            emphasis: {
              focus: "series",
            },
            data: []
          }
        ]
      }
    }
  },
  mounted(){
    this.getChartData()
  },
  methods:{
    moment,
    // 获取图表数据
    getChartData(){
      this.loading = true
      const data = { pageNum: 1, pageSize: 1000 }
      getDeviceList(data)
      .then(async (res)=>{
        const { data: { pageData } } = res
        // console.log(pageData)
        const channelCodeArray = pageData.map(item => { return item.channelCode })
        const xAxisData = pageData.map(item => { return item.channelName })
        const startDate = this.startDate.unix()
        const endDate = this.endDate.unix()
        this.option.xAxis[0].data = xAxisData
        for(let i = 0; i < channelCodeArray.length; i++){
          const data = { data: { startDate, endDate, channelIds:[ channelCodeArray[i] ], minSpeed: 0, maxSpeed: 20 } }
          await getPassingTotal(data)
          .then( (res) => {
            const { data } = res
            // console.log(data)
            this.option.series[0].data.push(data)
          })
          .catch(err => { console.log(err) })
          const datas = { data: { startDate, endDate, channelIds:[ channelCodeArray[i] ], minSpeed: 21, maxSpeed: 220 } }
          await getPassingTotal(datas)
          .then( (res) => {
            const { data } = res
            // console.log(data)
            this.option.series[1].data.push(data)
          })
          .catch(err => { 
            console.log(err) 
          })
        }
        this.resize()
        this.loading = false
      })
      .catch(err=>{
        console.log(err)
        this.loading = false
      })
    },
    // 图表渲染就自适应的方法
    resize() {
      let myChart = this.$echarts.init(document.getElementById('velocityMeasurement_actualData_echarts'))
      let resizeDiv = document.getElementById('velocityMeasurement_actualData_echarts')
      myChart.setOption(this.option)
      let listener = () => { myChart.resize() }
      EleResize.on(resizeDiv, listener)
    },
    // 开始日期限制选择范围（未来日期不可选）
    disabledStartDate(startValue) {
      // 获取计算机当前日期
      const currentDateValue = moment()
      if (!startValue) { return false }
      // 禁用大于计算机日期的日期值
      return startValue > currentDateValue
    },
    // 终止日期/时间的日期不可选范围
    disabledEndDate(endValue) {
      const startDateValue = this.startDate
      const maxDateValue = moment(startDateValue._d).add(1, "months")
      const currentDateValue = moment().endOf("day")
      if (!endValue) { return false }
      return ( startDateValue > endValue || endValue > currentDateValue || maxDateValue < endValue)
    },
    // 初始日期/时间选择变化回调
    handleCalendarChange(value, mode) {
      const currentDateValue = moment()
      const startDateValue = moment(value._d)
      const endDateValue = this.endDate
      const maxDateValue = moment(value._d).add(1, 'months')
      // 判断初始日期时间是否空值
      if(value){
        if(endDateValue > maxDateValue){
          this.endDate = moment(value._d).add(1, 'months').subtract(1, 'day').endOf('day')
        }else if(startDateValue > endDateValue){
          if(currentDateValue > maxDateValue){
            this.endDate = moment(value._d).add(1, 'months').subtract(1, 'day').endOf('day')
          }else{
            this.endDate = moment().endOf('day')
          }
        }
      }
    },
  }
}
</script>

<style scoped>
#velocityMeasurement-actualData{
  width: 100%;
  height: 100%;
}
.main{
  width: 100%;
  height: calc(100% - 50px);
  padding: 15px 20px;
}
.inquire{
  margin-bottom: 15px;
}
.main-echarts{
  width: 100%;
  height: calc(100% - 47px);
}
</style>